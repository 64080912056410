import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import EditRegion from '../../components/region/editRegion';
import AddPlace from '../../components/place/addPlace';
import AddGatekeepers from '../../components/region/AddGatekeepers';
import AddOrganizer from '../../components/organizer/addOrganizer';
import DisplayPlaces from '../../components/place/DisplayPlaces';
import DisplayOrganizers from '../../components/organizer/DisplayOrganizers';
import SuggestionBlobs from '../../components/utilities/SuggestionBlobs';
import ScrapeBlobs from '../../components/utilities/ScrapeBlobs';
import RegionInfo from '../../components/region/RegionInfo';
import LoadingScreen from '../../components/utilities/LoadingScreen';

function EditRegionDetail() {
  const navigate = useNavigate();
  const { urlName } = useParams();
  const [region, setRegion] = useState(null); // Initialize region as null
  const [editRegion, setEditRegion] = useState(false);
  const [places, setPlaces] = useState(false)
  const [organizers, setOrganizers] = useState(false)
  const [suggestions, setSuggestions] = useState(false)
  const [scrapes, setScrapes] = useState(false)
  const [count, setCount] = useState(false)
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(region) {
      document.title = `Edit ${region.name} - Zeitgeists`;
    } else {
      document.title = `Region - Zeitgeists`;
    }
  }, [region]);
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };
  const fetchRegionDetails = async () => {
    try {
      const response = await fetch(`/api/region/${urlName}/edit`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setRegion(data.region);
        setOrganizers(data.organizers);
        setPlaces(data.places)
        setEditRegion(data.edit);
        setCount(data.count);
        setScrapes(data.scrapes);
        setSuggestions(data.suggestions);
        setLoading(false);
        

      } 
      else if (response.status === 401) {
        navigate(`/login?redirect=/region/${urlName}/`)
      }
      else if (response.status === 403){
        navigate(`/region/${urlName}/`)
      } else {
        // Handle invalid region name or other error scenarios
        console.log(`${urlName} is an invalid region name.`);
      }
    } catch (error) {
      console.log(error);
      navigate(`/login/region/${urlName}/`)

    }
  };
  useEffect(() => {
      fetchRegionDetails();    
  }, []);
  const StatusButton = ({ regionUrlName }) => {
    return (
      <div className="mt-6 mb-8">
        <Link 
          to={`/region/${regionUrlName}/status`}
          className="block w-full bg-blue-600 hover:bg-blue-700 text-white text-center font-semibold py-4 px-6 rounded shadow-sm"
        >
          Status
        </Link>
      </div>
    );
  };

  return (
    <LoadingScreen isLoading={loading} message="Fetching region details...">
    <div className='p-4 mt-16'>
      {region && (
        <div className='grid grid-cols-4' key={region.id}>
               <p className="col-span-4 text-center font-bold text-2xl m-4 underline"><Link to={`/region/${region.urlName}/`}> {region.name}</Link>                <StatusButton regionUrlName={region.urlName} />
               </p>

          <div className='col-span-4  justify-center'>{ editRegion === 'super' ? <EditRegion onChange={fetchRegionDetails} region={region}  /> :           <RegionInfo region={region} home={true} />}</div>

          <div className='col-span-4'></div>
          {suggestions && 
        <div className="col-span-4 md:col-span-1 sm:col-span-2  m-3 bg-yellow-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            <p className="text-5xl">{suggestions && suggestions.filter(suggestion => suggestion.listingId && suggestion.status==='unapproved').length}</p>
            <p className="text-xl">Listing Edit Suggestions</p>
            {open !== 'edits' && <button className="text-blue-600 hover:underline" onClick={()=>setOpen('edits')}>See listings suggestions</button>}
            {open === 'edits' && <button className="text-red-600 hover:underline" onClick={()=>setOpen(false)}>Close</button>}

        </div>
    }

    { suggestions &&
        <div className="col-span-4 sm:col-span-2 md:col-span-1 m-3 bg-purple-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            <p className="text-5xl">{suggestions && suggestions.filter(suggestion => !suggestion.listingId && suggestion.status==='unapproved').length}</p>
            <p className="text-xl">Listing Add Suggestions</p>
            {open !== 'adds' && <button className="text-blue-600 hover:underline" onClick={()=>setOpen('adds')}>See listings add suggestions</button>}
            {open === 'adds' && <button className="text-red-600 hover:underline" onClick={()=>setOpen(false)}>Close</button>}

        </div>
    }


    {scrapes&&
       <div className="col-span-4 sm:col-span-2  md:col-span-1 m-3 bg-blue-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            <p className="text-5xl">{scrapes.filter(scrape=>scrape.new).length}</p>
            <p className="text-xl">New Scrapes</p>
            {open !== 'scrapes' && <button className="text-blue-600 hover:underline" onClick={()=>setOpen('scrapes')}>See scrapes</button>}
            {open === 'scrapes' && <button className="text-red-600 hover:underline" onClick={()=>setOpen(false)}>Close</button>}

        </div>
    }

    {
        count  && <div className="col-span-4 sm:col-span-2  md:col-span-1 m-3 bg-green-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            <p className="text-5xl">{count.live}</p>
            <p className="text-xl">Upcoming Live Status Listings</p>
            <Link to={'/listings'} className="text-blue-600 hover:underline">See listings</Link>
        </div>
    }
    {open === 'adds' && suggestions && places && <SuggestionBlobs suggestions={suggestions.filter(suggestion=>suggestion.type==='add')} places={places} />
    }
   {open === 'edits' && suggestions && places && <SuggestionBlobs suggestions={suggestions.filter(suggestion=>suggestion.type==='edit')} places={places} />
    }
       {open === 'scrapes' && scrapes && places && <ScrapeBlobs places={places} scrapes={scrapes} />
    }
<div className='col-span-4 grid grid-cols-5'>

     {/* add places and organizers  */}

     <div className='col-span-5 md:col-span-2 lg:col-span-2'>
          {editRegion && <AddPlace onChange={fetchRegionDetails} regionName={region.urlName} />}
          </div>
          <div className='col-span-5 md:col-span-2 lg:col-span-2'>
          { editRegion&&  <AddOrganizer onChange={fetchRegionDetails} region={region} />}   
          </div>
          <div className='col-span-5 md:col-span-1 flex justify-center'>
            {editRegion && <div> <p className='text-lg'>Council:</p> <AddGatekeepers role={editRegion} region={region} /> </div>}
          </div>
</div>

     

       

          {places.length > 0 && <div className='col-span-4 text-center pt-6 pb-4 text-xl font-bold'>Places:</div>}

          {places &&<div className='col-span-4 flex'> <DisplayPlaces places={places} edit={true} gatekeeper={editRegion} onChange={fetchRegionDetails} /> </div>}
           {organizers.length > 0 && <div className=' col-span-4 text-center pt-6 pb-4 text-xl font-bold'>Organizers:</div>}
        {organizers && <div className='col-span-4 flex'><DisplayOrganizers organizers={organizers} edit={true} gatekeeper={editRegion} onChange={fetchRegionDetails} /></div>}
        </div>

      ) }

{region&&<div className="mt-60">
  <p className='font-bold underline'>Details</p>
  <div>
     
      <p><strong>REGION ID:</strong> - <span onClick={() => copyToClipboard(region.id)} style={{ cursor: 'pointer', color: 'blue' }}>{region.id}</span></p>
      <p><strong>REGION UUID:</strong> - <span onClick={() => copyToClipboard(region.uuid)} style={{ cursor: 'pointer', color: 'blue' }}>{region.uuid}</span></p>
    </div>
</div> }

      {/* Add place and delete place from region */}

    </div>
    </LoadingScreen>
  );
}

export default EditRegionDetail;
