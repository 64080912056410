import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import LoadingScreen from '../../components/utilities/LoadingScreen';
import RegionInfo from '../../components/region/RegionInfo';

function RegionStatus() {
  const navigate = useNavigate();
  const { urlName } = useParams();
  const [region, setRegion] = useState(null);
  const [places, setPlaces] = useState(false);
  const [organizers, setOrganizers] = useState(false);
  const [reports, setReports] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if(region) {
      document.title = `${region.name} - Zeitgeists`;
    } else {
      document.title = 'Region - Zeitgeists';
    }
  }, [region]);

  useEffect(() => {
    const fetchRegionDetails = async () => {
      try {
        const response = await fetch(`/api/region/${urlName}/status`, {
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });

        if (response.ok) {
          const data = await response.json();
          setRegion(data.region);
          setOrganizers(data.organizers);
          setPlaces(data.places);
          setReports(data.reports);
        } else if (response.status === 401) {
          navigate(`/login?redirect=/region/${urlName}/`);
        } else if (response.status === 403) {
          navigate(`/region/${urlName}/`);
        } else {
          console.log(`${urlName} is an invalid region name.`);
        }
        setLoading(false);
      } catch (error) {
        navigate(`/login?redirect=/region/${urlName}/`);
        console.log(error);
      }
    };

    fetchRegionDetails();
  }, [urlName, navigate]);

  const formatDate = (dateString) => {
    if (!dateString) return 'No date available';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  const truncateText = (text, maxLength = 100) => {
    if (!text) return '';
    return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  };

  return (
    <LoadingScreen isLoading={loading} message="Fetching region details...">
      <div className="max-w-6xl mx-auto px-4 py-8 p-4 mt-16">
        {region && (
          <div className="mb-8">
            <RegionInfo region={region} />
          </div>
        )}

        {reports && (
          <div className="mb-8">
            <h2 className="text-xl font-bold mb-4">Reports Status</h2>
            {reports.length > 0 ? (
              <div className="space-y-2">
                {reports.map((report) => (
                  <div key={report.id} className="p-3 bg-white rounded shadow-sm border border-red-100">
                    <div className="flex justify-between items-start">
                      <div>
                        <Link 
                          to={`/event/${report.listing?.uuid}`}
                          className="text-red-600 hover:text-red-800 font-medium"
                        >
                          {report.listing?.name || 'Untitled Listing'}
                        </Link>
                        {report.comment && (
                          <p className="text-sm text-gray-600 mt-1">
                            {truncateText(report.comment)}
                          </p>
                        )}
                      </div>
                      <span className="text-sm text-gray-500">
                        {formatDate(report.createdAt)}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="p-4 bg-green-50 rounded-lg text-center">
                <p className="text-green-700">All clear! No pending reports.</p>
              </div>
            )}
          </div>
        )}

        <div className="grid md:grid-cols-2 gap-8">
          {places && (
            <div>
              <h2 className="text-xl font-bold mb-4">Places</h2>
              <div className="space-y-2">
                {places.map((place) => (
                  <div key={place.id} className="p-3 bg-white rounded shadow-sm border">
                    <div className="flex justify-between items-start mb-2">
                      <h3 className="font-medium">{place.name}</h3>
                      <span className="text-sm text-gray-500">
                        {place.liveListingsCount} scheduled upcoming events
                      </span>
                    </div>
                    <div className="space-y-1 text-sm">
                      {place.lastEdit && (
                        <div className="text-gray-600">
                          Last edit: {formatDate(place.lastEdit.createdAt)}
                          {place.lastEdit.listingUUID && (
                            <Link 
                              to={`/event/${place.lastEdit.listingUUID}`}
                              className="ml-2 text-blue-600 hover:text-blue-800"
                            >
                              View
                            </Link>
                          )}
                        </div>
                      )}
                      {place.lastAdd && (
                        <div className="text-gray-600">
                          Last add: {formatDate(place.lastAdd.createdAt)}
                          {place.lastAdd.listingUUID && (
                            <Link 
                              to={`/event/${place.lastAdd.listingUUID}`}
                              className="ml-2 text-blue-600 hover:text-blue-800"
                            >
                              View
                            </Link>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="mt-2 space-x-3 text-sm">
                      <Link 
                        to={`/region/${region.urlName}/place/${place.urlName}`}
                        className="text-gray-600 hover:text-gray-800"
                      >
                        Calendar →
                      </Link>
                      {place.url && (
                        <a 
                          href={place.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-gray-600 hover:text-gray-800"
                        >
                          Website →
                        </a>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {organizers && (
            <div>
              <h2 className="text-xl font-bold mb-4">Organizers</h2>
              <div className="space-y-2">
                {organizers.map((organizer) => (
                  <div key={organizer.id} className="p-3 bg-white rounded shadow-sm border">
                    <div className="flex justify-between items-start mb-2">
                      <h3 className="font-medium">{organizer.name}</h3>
                      <span className="text-sm text-gray-500">
                        {organizer.liveListingsCount} upcoming live listings
                      </span>
                    </div>
                    <div className="space-y-1 text-sm">
                      {organizer.lastEdit && (
                        <div className="text-gray-600">
                          Last edit: {formatDate(organizer.lastEdit.createdAt)}
                          {organizer.lastEdit.listingUUID && (
                            <Link 
                              to={`/event/${organizer.lastEdit.listingUUID}`}
                              className="ml-2 text-blue-600 hover:text-blue-800"
                            >
                              View
                            </Link>
                          )}
                        </div>
                      )}
                      {organizer.lastAdd && (
                        <div className="text-gray-600">
                          Last add: {formatDate(organizer.lastAdd.createdAt)}
                          {organizer.lastAdd.listingUUID && (
                            <Link 
                              to={`/event/${organizer.lastAdd.listingUUID}`}
                              className="ml-2 text-blue-600 hover:text-blue-800"
                            >
                              View
                            </Link>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="mt-2 space-x-3 text-sm">
                      <Link 
                        to={`/region/${region.urlName}/organizer/${organizer.urlName}`}
                        className="text-gray-600 hover:text-gray-800"
                      >
                        Calendar →
                      </Link>
                      {organizer.url && (
                        <a 
                          href={organizer.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-gray-600 hover:text-gray-800"
                        >
                          Website →
                        </a>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </LoadingScreen>
  );
}

export default RegionStatus;